import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Iframe from "react-iframe"
import { Helmet } from "react-helmet"

import Layout from "../../components/common/layout/layout"
import SEO from "../../components/common/layout/seo"
import Navigation from "../../components/common/navigation/navigation"
import Footer from "../../components/sections/footer"

import skilling from "../../images/icons/Icon/Skilling.png"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Popup from "../../components/Popup"
import { SectionDivider, HalfColumn, TwoColumn } from "../../components/global"
import { render } from "react-dom"
import parse from "html-react-parser"

const query = graphql`
  query {
    allStrapiBlog(sort: { fields: Date, order: DESC }) {
      edges {
        node {
          id
          Title
          Blog_description
          Date(formatString: "MMMM DD, YYYY")
          Main_content
          Image {
            childImageSharp {
              fixed(width: 400) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    allStrapiMasterContent {
      edges {
        node {
          id
          master_contentTitle
          master_contentImage {
            childImageSharp {
              fluid(maxWidth: 1550, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

const Blogs = () => (
  <Layout>
    <Helmet>
      <title>Blogs By Dili - Zero Cost EMI Option for Professional Skilling</title>
      <meta
        name="description"
        content="Pay through a Dili no-cost EMI and signup for career/skill growth courses like AI & Machine learning, Digital Marketing, Part-time MBA and more."
      />
      <script src="/Jslibrary.js" defer></script>
    </Helmet>
    <Navigation></Navigation>
    <CategoryContainer>
      <Stores>
        <StaticQuery
          query={query}
          render={(data) => (
            <StoreC_Container>
              <StoreC1>
                {data.allStrapiMasterContent.edges
                  .filter(
                    (Master_content) =>
                      Master_content.node.master_contentTitle ===
                      "blog_page_header"
                  )
                  .map((Master_content) => (
                    <BlogPageHeaderImgContainer>
                      <BlogPageHeaderImg
                        fluid={
                          Master_content.node.master_contentImage
                            .childImageSharp.fluid
                        }
                      />
                    </BlogPageHeaderImgContainer>
                  ))}
              </StoreC1>
              <StoreC>
                {data.allStrapiBlog.edges
                  // .filter((blog) => store.node.storeCategory === "career")
                  .map((blog) => (
                    <Store>
                      <ImgContainer>
                        <StoreImg
                          fixed={blog.node.Image.childImageSharp.fixed}
                        />
                      </ImgContainer>
                      <StoreTextContainer>
                        <BlogDate>{blog.node.Date}</BlogDate>
                        <StoreHeader id={"StoreHeader_" + blog.node.id}>
                          {blog.node.Title}
                        </StoreHeader>
                        <StoreText id={"StoreText_" + blog.node.id}>
                          {parse(blog.node.Blog_description)}
                        </StoreText>
                        <ReadMoreText>
                          <Link to={"/blogs/blog?id=" + blog.node.id}>
                            Read More
                          </Link>
                        </ReadMoreText>
                      </StoreTextContainer>
                    </Store>
                  ))}
              </StoreC>
            </StoreC_Container>
          )}
        />
      </Stores>
    </CategoryContainer>
    <Footer />
  </Layout>
)

export default Blogs

export const Spacing = styled.div`
  margin-bottom: 50px;
`

export const CategoryContainer = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 110px;
  border: 0px solid blue;
`

export const CategoryHeader = styled.div`
  color: #142630;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: -0.02em;
  margin-right: 40px;
`

export const CategoryText = styled.div`
  color: #465f6d;
  font-size: 20px;
  letter-spacing: -0.02em;
  line-height: 1.5;
  margin-top: 20px;
  width: 100%;
  text-align: justify;
`

export const Stores = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 0px solid red;
  justify-content: center;
`

export const Store = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 24px;
  border-radius: 10px;
  border: 1px solid #9c9c9c;
`

export const ImgContainer = styled.div` 
  border: 0px solid #dcdcdc; 
  position: relative;  
  margin-top:-3px;
  }
`

export const StoreImg = styled(Img)`
  margin: 0;
  position: absolute;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  top: 50%;
  left: 50%;
  max-width: 100%;
  max-height: 100%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`

export const BlogPageHeaderImgContainer = styled.div` 
  border: 0px solid #dcdcdc; 
  position: relative;  
  margin-top:0px;
  }
`

export const BlogPageHeaderImg = styled(Img)`
  margin: 0;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  -ms-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
`
export const StoreHeader = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
  justfy-content: justify;
  width: 100%;
  height: 84px;
  border: 0px solid green;
  padding: 2px;
`
export const StoreTextContainer = styled.div`
  width: 370px;
  height: 250px;
  border: 0px solid #e6e6e6;
  margin-bottom: 10px;
  margin-left: 15px;
  margin-right: 15px;
  background-color: #ffffff;
`

export const StoreText = styled.div`
  font-size: 15px;
  width: 100%;
  height: 100px;
  line-height: 1.3;
  overflow-x: hidden;
  overflow-y: hidden;
  text-align: justify;
  border: 0px solid red;
  padding: 2px;
`

export const BlogDate = styled.div`
  font-size: 12px;
  font-weight: bold;
  width: 100%;
  line-height: 1.3;
  text-align: justify;
  margin-top: 5px;
  padding: 2px;
`

export const ReadMoreText = styled.div`
  margin-left: auto;
  margin-right: auto;
  font-size: 13px;
  width: 100%;
  padding-right: 5px;
  padding-top: 4px;
  text-align: center;
  border-top: 1px solid #9c9c9c;
  text-decoration: none;
  color: black;
`

export const StoreButtonContainer = styled.div`
  width: 96px;
`

export const StoreButton = styled.button`
  background: #505050;
  color: white;
  font-weight: bold;
  font-size: 14px;
  width: 96px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 40px;
`

const FormText = styled.div`
  flex-direction: column;
  margin-top: 0px;
`

const FormHeader = styled.div`
  font-size: 36px;
  font-weight: bold;
  color: #142630;
  width: 60%;

  @media (max-width: ${(props) => props.theme.screen.md}) {
  }

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    font-size: 40px;
    width: 80%;
  }

  @media (max-width: ${(props) => props.theme.screen.xs}) {
    font-size: 24px;
    width: 80%;
  }
`

const FormSubheader = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  text-align: justify;
  font-size: 20px;
  color: #465f6d;
  line-height: 1.5;

  @media (max-width: ${(props) => props.theme.screen.md}) {
  }

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    width: 480px;
  }

  @media (max-width: ${(props) => props.theme.screen.xs}) {
    width: 360px;
    font-size: 14px;
  }
`

const FormContainer = styled.div`
  display: flex;
  padding-top: 24px;
  padding-bottom: 80px;
`

const FormEmbed = styled(Iframe)`
  width: 640px;
  height: 640px;
`

export const StoreC = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 0px solid green;
  justify-content: center;
`

export const StoreC1 = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  border: 0px solid blue;
`

export const StoreC_Container = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border: 0px solid blue;
`
export const BackLink = styled(Link)`
  margin-bottom: 80px;
`
